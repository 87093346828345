.tags_td > .ant-table-body {
  display: flex;
}

.red-row {
  background-color: red;
}
.grey-row {
  background-color: grey;
}

.phoneInputStyle {
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  margin: 0 !important;
  padding: 0 !important;
  font-variant: tabular-nums !important;
  list-style: none !important;
  -webkit-font-feature-settings: "tnum" !important;
  font-feature-settings: "tnum", "tnum" !important;
  position: relative !important;
  display: inline-block !important;
  width: 100% !important;
  height: 32px !important;
  padding: 4px 55px !important;
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  background-color: #fff !important;
  background-image: none !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 4px !important;
  -webkit-transition: all 0.3s !important;
  transition: all 0.3s !important;
}

.phoneDropDown {
  position: absolute !important;
  top: 0px !important;
  bottom: 0 !important;
  padding: 0 !important;
  background-color: #f5f5f5 !important;
  border: 1px solid #cacaca !important;
  border-radius: 3px 0 0 3px !important;
}
.phoneDropDown2 {
  position: absolute;
  top: 4px !important;
  bottom: 0 !important;
  padding: 0;
  background-color: #f5f5f5;
  border: 1px solid #cacaca;
  border-radius: 3px 0 0 3px;
  height: 32px;
}
.test > td {
  padding: 8px 20px !important;
}
.btnQuickActions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.btnQuickActions > button {
  margin-bottom: 5px;
}

.notes_item > .ant-spin-nested-loading > .ant-spin-container > .ant-list-items {
  width: 100%;
  text-align: justify;
}

.room-table-row > td {
  padding: 0.4rem !important;
}
.tree-parent > .ant-select-tree-node-content-wrapper > .ant-select-tree-title {
  font-weight: 600;
  color: black;
}
