.ant-list.ant-list-split .ant-spin-nested-loading .ant-spin-container {
  display: flex;
  flex-wrap: wrap;
}
.ant-list.ant-list-split .ant-spin-nested-loading .ant-spin-container .ant-col {
  float: none;
}

.react-tel-input .form-control {
  width: 100%;
}

